const categories = [
	["All", "light"],
	["Python", "warning"],
	["Android", "success"],
	["HTML/CSS", "primary"],
	["Javascript", "danger"],
	// ["C/C++", "info"]
]

const projects = [
	{
		"img": require('./img/projects/howsmydriving.jpg'),
		"title": "How's My Driving?",
		"description": "Through this project, OBD-II data can be used to analyze trips and driver behavior to improve driving efficiency, safety, and overall performance.",
		"link": "https://obd.ninkuk.com/",
		"category": "Python"
	},
	{
		"img": require('./img/projects/ANTM.jpg'),
		"title": "Antmanirbhar Tarun Manch (Self Reliance)",
		"description": "A non-profit initiative to provide a platform for the newly launched and growing local businesses in Indore.",
		"link": "https://play.google.com/store/apps/details?id=com.ninkuk.atmanirbharbharat_tarunmanch&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pli=1",
		"category": "Android"
	},
	{
		"img": require('./img/projects/psyche_capstone.jpg'),
		"title": "NASA Psyche - Visualizing Space Data Footprints",
		"description": "Tool created for the NASA Psyche Mission to calculate geometrical outlines of an asteroid given a specific image in time.",
		"link": "https://github.com/Ninkuk/2022_19A_Visualizing-Space-Data-Footprints#nasa-psyche-capstone-19a-visualizing-space-data-footprints-copper-class---2022",
		"category": "Python"
	},
	// {
	// 	"img": "",
	// 	"title": "Misinformation Dashboard",
	// 	"description": "Dashboard to visualize the characteristics and influence of misinformation spread",
	// 	"link": "",
	// 	"category": "Javascript Python"
	// },
	{
		"img": require('./img/projects/psyche_inspired.jpg'),
		"title": "Psyche Inspired Review App",
		"description": "An internal system to submit and review NASA Psyche Inspired Intern Applications",
		"link": "https://psyche.asu.edu/get-involved/psyche-inspired/",
		"category": "HTML/CSS"
	},
	{
		"img": require('./img/projects/P2F.jpg'),
		"title": "Path to FBLA",
		"description": "Android app which helps prepare for objective tests hosted by the Future Business Leaders of America. The user can take quizzes from 10 different categories with over 150 questions.",
		"link": "https://github.com/Ninkuk/FBLA_Mobile_App_Development",
		"category": "HTML/CSS"
	},
	{
		"img": require('./img/projects/wallpaper.jpg'),
		"title": "Wallpaper Automation",
		"description": "Python scripts to download and set images from popular stock photography as background wallpapers. Use along with Linux cron or Windows task scheduler to create a slideshow of fresh new wallpapers.",
		"link": "https://github.com/Ninkuk/Wallpaper-Automation",
		"category": "Python"
	},
	{
		"img": require('./img/projects/f1_dashboard.jpg'),
		"title": "Formula 1 Dashboard",
		"description": "Visualizations Dashboard for Formula 1 statistics using D3.js",
		"link": "https://ninkuk.github.io/F1-Viz-Dashboard/",
		"category": "Javascript"
	},
	{
		"img": require('./img/projects/american_food.jpg'),
		"title": "American Food Access Visualizations",
		"description": "Through this project we hope to show a clearer picture about the severity of America's food deserts and the type of life people in food deserts experience on the daily.",
		"link": "https://github.com/Ninkuk/American-Food-Access-Visualization",
		"category": "Javascript"
	},
	{
		"img": require('./img/projects/asu.jpg'),
		"title": "ASU Automation Scripts",
		"description": "Python scripts to automate myASU and Canvas tasks. Includes utilities for class search and availability.",
		"link": "https://github.com/Ninkuk/ASU_Scripts",
		"category": "Python"
	},
	// {
	// 	"img": "",
	// 	"title": "Photoshelter Migration",
	// 	"description": "",
	// 	"link": "",
	// 	"category": "Python"
	// },
]

const workExperience = [
	{
		"title": "Learning Research Lab",
		"employee": "Dr. Leland Hartwell",
		"description": "Under the guidance of Dr. Hartwell, I explored the concept of “doing” science rather than simply reading about it. I explored the phenomenon of visual cognitio by conducting behavioral experiments that would derive some meaning from our visual experience. Additionally, I learned to create behavioral experiments using th jsPsych library and worked towards creating a streamlined system of quick experiment generation through templates. Further research can lead to insights into how huma cognition works and how we can replicate that for artificial systems and computer vision challenges.",
		"startDate": "August 2022",
		"endDate": "December 2022",
		"link": "",
		"category": "Research"
	},
	{
		"title": "Research Programming Intern",
		"employee": "NASA Psyche Mission",
		"description": "Part of the student collaboration team at NASA Psyche Mission. Involved in developing and maintaining software including Psyche mobile apps and website. Assisted the Senior Capstone teams and Psyche Inspired students. Engaged in public outreach programs.",
		"startDate": "April 2021",
		"endDate": "August 2022",
		"link": "",
		"category": "Internship"
	},
	{
		"title": "Misinformation Research Lab",
		"employee": "Unit for Data Science",
		"description": "Under the guidance of Director Michael Simeone, I worked with Twitter and Parler data related to the 2021 US Capitol attack, implementing a dashboard to visualize th characteristics and influence of misinformation spread and expanding my knowledge of Python’s data science libraries. The lab’s aim was not to classify the information as tru or false but to produce tools for understanding the salient features of misinformation. This project will benefit further misinformation research for practical implementations b policymakers.",
		"startDate": "August 2021",
		"endDate": "December 2021",
		"link": "",
		"category": "Research"
	},
	{
		"title": "Student Graphic Designer",
		"employee": "ASU Enterprise Marketing Hub",
		"description": "",
		"startDate": "January 2020",
		"endDate": "April 2021",
		"link": "https://brandguide.asu.edu/",
		"category": "Student Job"
	},
]

const achievements = [
	{
		"img": require('./img/achievements/Card_Governer_2018.jpg'),
		"title": "Governor of Arizona Commendation",
		"description": "",
		"date": ""
	},
	{
		"img": require('./img/achievements/deans_list.jpg'),
		"title": "Dean's List",
		"description": "",
		"date": ""
	},
	// {
	// 	"img": "",
	// 	"title": "FBLA E-Business",
	// 	"description": "",
	// 	"date": ""
	// },
	// {
	// 	"img": "",
	// 	"title": "FBLA Mobile Application Development",
	// 	"description": "",
	// 	"date": ""
	// }
]

export {categories, projects, workExperience, achievements};
