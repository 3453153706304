import React from 'react';

function WorkCard(props) {
	return (
		<div className="card text-bg-dark bg-transparent border border-2 border-light border-opacity-25 h-100">
			<div className="card-body">
				<h5 className="card-title fw-bold">{props.work.title}</h5>
				<h6>{props.work.employee} ⋅ {props.work.startDate} - {props.work.endDate}</h6>
				<p className="card-text" style={{fontSize: "smaller"}}>{props.work.description}</p>
				{/*<a href={props.work.link} className="btn btn-primary stretched-link">Go somewhere</a>*/}
			</div>
		</div>
	);
}

export default WorkCard;
